<template>
  <div v-if="data" class="d-flex">
    <!-- 圖片(檢視模式) -->
    <div class="mr-8" v-if="mode == 'simple'">
      <v-img
        class="my-4 elevation-4"
        :src="instancePhotoUrl"
        width="100"
        height="100"
      >
        <v-icon
          v-if="!instancePhotoUrl"
          style="position: absolute; top: 38%; left: 38%"
          size="24"
        >
          fa fa-image
        </v-icon>
      </v-img>
    </div>

    <v-card
      class="mb-8 flex-grow-1"
      elevation="8"
    >
      <!-- instance名稱(檢視模式) -->
      <v-card-title v-if="!isDefaultInstance && mode == 'simple'">
        <span>{{ $t('product.data.spec') }}: {{instance.name}}</span>
      </v-card-title>

      <!-- instance資料(檢視模式) -->
      <v-card-text v-if="mode == 'simple'">
        <div class="mr-10">
          <span>{{$t('data.price')}} : {{instance.price}}</span>
          <span> / </span>
          <span>{{$t('product.data.sku')}} : {{instance.sku}}</span>
          <span> / </span>
          <span>{{$t('product.data.stock')}} : {{instance.stock}}</span>
        </div>
      </v-card-text>

      <!-- instance資料(編輯模式) -->
      <v-card-text v-if="mode == 'edit'">
        <v-row>
          <v-col cols="12" xl="4" lg="4" md="4" v-if="!isDefaultInstance">
            <photo-selector
              photoManager
              type="product"
              v-model="instance.photo"
            ></photo-selector>
          </v-col>

          <v-col cols="12" xl="8" lg="8" md="8">
            <v-text-field
              v-if="multiInstances"
              dense
              outlined
              readonly
              :label="$t('product.data.spec')"
              :value="instance.name"
            ></v-text-field>

            <v-text-field
              dense
              outlined
              type="number"
              :label="$t('data.price')"
              v-model="instance.price"
            ></v-text-field>

            <v-text-field
              dense
              outlined
              :label="$t('product.data.sku')"
              v-model="instance.sku"
            ></v-text-field>

            <v-text-field
              dense
              outlined
              :label="$t('product.data.stock')"
              v-model="instance.stock"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- instance操作 -->
      <v-card-actions v-if="!isDefaultInstance">
        <v-btn
          icon
          color="orange"
          v-if="quantity>1"
          class="product-instance-draggable"
        >
          <v-icon>fa fa-bars</v-icon>
        </v-btn>

        <v-btn
          icon
          color="error"
          v-if="quantity>1"
          @click="removeConfirm"
        >
          <v-icon>fa fa-trash</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    multiInstances: Boolean,
    mode: String,
    instance: Object,
    index: Number,
    quantity: Number,
  },
  data: () => ({
    data: null
  }),
  methods: {
    removeConfirm() {
      this.$apopup.base({
        title: this.$t(`remove.confirm`),
        applyCallback: async () => {
          this.$emit('remove', this.index)
        },
      })
    },
  },
  computed: {
    isDefaultInstance() {
      return this.instance.name === null
    },
    instancePhotoUrl() {
      return this.$helper.getPhotoUrl(this.instance.photo) || null
    },
  },
  watch: {
    instance: {
      immediate: true,
      deep: true,
      handler() {
        this.data = window.eagleLodash.cloneDeep(this.instance)
      },
    },
    data: {
      deep: true,
      handler() {
        if(window.eagleLodash.isEqual(this.data, this.instance)) return
        this.$emit('update:instance', window.eagleLodash.cloneDeep(this.data))
      },
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
